import PropTypes from "prop-types";
import React from "react";
import { BiMenu, BiUserCircle } from "react-icons/bi";
import Logo from "./Logo";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function Header({ isExpanded, toggleExpansion, color, setDrawerState }) {
  const theme = useTheme();
  const upToXl = useMediaQuery(theme.breakpoints.up("xl"));
  const betweenMdlg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const downToMdSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="header-fix ">
      <nav
        style={{ maxWidth: upToXl ? "1477px" : "1200px", paddingLeft:betweenMdlg?"27.5px":downToMdSize?"15px":"0px", paddingRight:betweenMdlg?"27.5px":downToMdSize?"15px":"0px" }}
        className="flex  mx-auto  items-center justify-between py-6 header-bg"
      >
        <div className="flex items-center  mr-6 text-white">
          <a href="/">
            <Logo color={color} />
          </a>
        </div>
        <div className="block lg:hidden">
          {/* This button make visible the menu component */}
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            className="flex items-center px-4 py-2 text-white border border-white hover:text-white hover:border-white"
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        {/* This div is visible dynamic with the state*/}
        <div className={`${isExpanded ? `block` : `hidden`} w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
          <div className="text-sm lg:flex-grow"></div>
          <div className="flex justify-end items-center">
            <button
              style={{
                border: `0.5px solid ${color}`,
                color: `${color}`,
              }}
              onClick={() => setDrawerState(true)}
              target="_blank"
              rel="noreferrer"
              className="contact inline-block mr-3 px-14 py-2 mt-4 text-sm  text-white hover:border-transparent border-wc hover:text-primary hover:bg-white lg:mt-0"
            >
              Contact Us
            </button>
            <button
              href="#"
              style={{
                borderLeft: `0.5px solid ${color}`,
                color: `${color}`,
                borderTop: `0.5px solid ${color}`,

                borderBottom: `0.5px solid ${color}`,
              }}
              
              onClick={event =>  window.location.href='https://app.fortexdesign.com/login'}
              target="_blank"
              rel="noreferrer"
              className="inline-block user mt-4 text-xl leading-none border-w hover:border-transparent hover:text-primary hover:bg-white lg:mt-0"
            >
              <BiUserCircle fontSize={22} />
            </button>
            <button
              onClick={() => toggleExpansion(true)}
              href="#"
              style={{
                border: `0.5px solid ${color}`,
                color: `${color}`,
                padding: "8px 15px !important",
              }}
              className="inline-block mt-4 text-xl leading-none border-w hover:border-transparent hover:text-primary hover:bg-white lg:mt-0"
            >
              <BiMenu fontSize={22} />
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
